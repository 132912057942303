import React from 'react';
import {connect} from "react-redux";
import {Route} from "react-router";
import {selectIndividualProvider} from "../../redux/provider/provider.selectors";
import './providers-template.styles.scss';
import {Helmet} from "react-helmet";

const ProviderTemplatePage = ({ provider, match }) => {
    return (
        <main>
            <Helmet>
                <title>{provider.providerName} | Urology Specialists of Nevada</title>
            </Helmet>
        <section className="container-fluid block-container-3 breathing-room provider-single shop-page">
            <div className="row justify-content-around">
                <a href="/providers" className="provider-single-breadcrumb">Providers</a>
                <div className="col-lg-3 col-md-4 col-sm-12 no-padding text-centered">
                    <img src={provider.providerInteriorImage} />
                </div>
                <div className=" col-md-9 col-sm-12 no-padding info">
                    <h1 className="border-bottom">{provider.providerName}, {provider.providerTitle}</h1>
                    <h2>Overview</h2>
                    <h4>Biography</h4>
                    <p className="border-bottom">
                        <div dangerouslySetInnerHTML={{__html: provider.providerBio}} />
                    </p>
                    <div className="breathing-room">
                        <h3>Qualifications</h3>
                        {
                            provider.qualifications.map(function (item, i) {
                                return (
                                    <div key={i} className="media">
                                        <i className={`fas mr-3 align-self-center fa-${item.icon}`}
                                           aria-hidden="true"></i>
                                        <div className="media-body">
                                                {provider.qualifications[i].type}<br/>
                                                <strong><div dangerouslySetInnerHTML={{__html: provider.qualifications[i].name}} /></strong>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>

                    {provider.organizations.length > 0  &&
                    <div className="breathing-room-bottom organizations-section">
                        <hr/>
                        <h3 className='organizations-heading'>Professional Organizations</h3>
                        <ul>
                            {
                                provider.organizations.map(function (item, i) {
                                    return (
                                        <li key={i}>{provider.organizations[i]}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>}

                    <div className="row">
                        <div className="col-lg-6 ">
                            <h1 className="border-top">Languages</h1>
                            <p><i className="fas fa-globe-americas mr-3 align-self-center" aria-hidden="true"></i>I
                                speak <b>English</b></p>

                        </div>
                        <div className="col-lg-6">
                            <h1 className="border-top">Gender</h1>
                            <p>
                                <i className="fas fa-user-md mr-3 align-self-center" aria-hidden="true"></i>{provider.sex}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Route path={`${match.path}/:providerId`}/>
        </section>
            </main>
    )
};

const mapToStateProps = (state, ownProps) => ({
    provider: selectIndividualProvider(ownProps.match.params.providerId)(state)
})

export default connect(mapToStateProps)(ProviderTemplatePage);
