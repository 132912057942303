const LOCATIONS_DATA = {
    sections: [
        {
            title: 'Central Campus',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJz3MOq-vDyIARMEC15rd7zHo&key=AIzaSyDvZ9yFv_TOcGvXg3BEPDqx0e8Z8zcol3M',
            linkUrl: 'https://maps.google.com/maps?ll=36.162062,-115.16845&z=16&t=m&hl=en-US&gl=US&mapclient=embed&daddr=2010%20Wellness%20Way%20Las%20Vegas%2C%20NV%2089106@36.1620623,-115.1684504',
            address1: '2010 Wellness Way, Ste. 200',
            address2: 'Las Vegas, NV 89106',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-877-0814',
            fax: '1-702-877-3238',
            id: 1
        },
        {
            title: 'Green Valley Campus',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJRbT8RSnOyIARLHjM_j0wSHA&key=AIzaSyDvZ9yFv_TOcGvXg3BEPDqx0e8Z8zcol3M',
            linkUrl: 'https://maps.google.com/maps?ll=36.0316,-115.09928&z=16&t=m&hl=en-US&gl=US&mapclient=embed&daddr=58%20N%20Pecos%20Rd%20Henderson%2C%20NV%2089074@36.0316001,-115.0992803',
            address1: 'Pecos Commons Building 58 N. Pecos Rd.',
            address2: 'Henderson, NV 89074',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-877-0814',
            fax: '1-702-877-3238',
            id: 2
        },
        // {
        //     title: 'Oakey Healthcare Center',
        //     message: 'Closing Friday, October 11, 2024',
        //     mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJd5M94UPByIARMX896MXjB6E&key=AIzaSyDvZ9yFv_TOcGvXg3BEPDqx0e8Z8zcol3M',
        //     linkUrl: 'https://goo.gl/maps/A6oenTuaMJWCbQKL7',
        //     address1: '4750 W Oakey Blvd., Ste. 2B',
        //     address2: 'Las Vegas, NV 89102',
        //     hours: 'Monday–Friday, 8 a.m–5 p.m.',
        //     phone: ' 1-702-877-0814',
        //     fax: '1-702-877-3238',
        //     id: 3
        // },
        {
            title: 'Southwest Campus',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ2YcorfPHyIARr4bEi52MkJQ&key=AIzaSyDvZ9yFv_TOcGvXg3BEPDqx0e8Z8zcol3M',
            linkUrl: 'https://www.google.com/maps/place/8410+W+Warm+Springs+Rd+%2310,+Las+Vegas,+NV+89113/@36.0559437,-115.2778602,17z/data=!3m1!4b1!4m5!3m4!1s0x80c8c7f3ad2887d9:0xe5edfdc4ea52726e!8m2!3d36.0559394!4d-115.2756715?hl=en-US',
            address1: '8410 W. Warm Springs Rd, Suite 10',
            address2: 'Las Vegas, NV 89113',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-877-0814',
            fax: '1-702-877-3238',
            id: 4
        }
    ]
}

export default LOCATIONS_DATA;
